.container {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/cubic2.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
}

.logoContainer {
    margin: 20px;
    margin-bottom: 60px;
    background-color: #2b2d42;
    border-radius: .8rem;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.textContainer {
  text-align: center;
  margin-bottom: 20px;
}

.titleWelcome {
    font-size: 32px;
    color: var(--light-white);
    margin-bottom: 10px;
}

.subtitleWelcome {
    font-size: 18px;
    color: var(--light-white);
}

.glass {
  background: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 50px;
  transition: 0.6s;
  transform-style: preserve-3d;
}

.newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-bottom: 20px;
  margin-top: 10px;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
}
  
.newButton:hover {
  background: var(--green);
  color: var(--light-black);
  border-radius: 5px;
  cursor: pointer;
}