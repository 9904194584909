.container {
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  height: 90%;
}

.textContainer {
  text-align: center; 
  line-height: 0.1;
}

.titleHome {
  font-size: 1.6rem;
  color: var(--light-white);
}

.subTitleHome {
  color: var(--light-white);
  font-size: 1.7rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgContainer {
  margin: 20px;
  margin-bottom: 50px;
  border-radius: .8rem;
}

.image {
  width: 100%;
  border-radius: 10px;
}

.glass {
  background: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 8px;
  transition: 0.6s;
	transform-style: preserve-3d;
  margin-bottom: auto;
  margin-top: auto;
}

.newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
}

.newButton:hover {
  background: var(--green);
  color: var(--light-black);
  border-radius: 5px;
  cursor: pointer;
}

.newButton:hover .icon {
  transition: all 0.5s;
  filter: invert(0);
  --webkit-filter: invert(0);
}

.icon {
  width: 24px;  
  margin-right: 10px;  
  filter: invert(1);
  position: relative;
  top: 7px;
}

.newButton:hover .icon {
  transition: all 0.5s;
  fill: var(--black);
}