.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.logoContainer {
  margin-bottom: 40px;
}

.logo {
  width: 100%;
  height: auto;
  border-radius: .8rem;
}

.createAccount {
  color: #333;
  margin-top: 24px;
  font-size: 1.2rem;
}

.signupForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 40px 70px 30px 60px;
  background-color: #ffffff; /* colore del form */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* ombra leggera */
  margin-top: 24px;
}

/* Stile per gli input all'interno del form */
.signupForm input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc; /* colore del bordo da abbinare agli altri moduli */
  border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: var(--white) !important;
}

/* Stile per i pulsanti all'interno del form */
.signupForm button {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.869);
  border-radius: 10px;
  padding: 4px 12px 2px 12px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.signupForm button:hover {
  background-color: rgb(255, 255, 255);
}

.glass {
  background: var(--primary-color);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.35);
  padding: 20px;
  padding-bottom: 50px;
}

.privacyPolicy {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  font-size: 12px;
  color: var(--light-white);
}

.privacyPolicy input[type="checkbox"] {
  appearance: none;
  width: 6px;
  height: 6px;
  margin-right: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.privacyPolicy input[type="checkbox"]:checked {
  background-color: var(--green);
  border-color: var(--primary-color);
}

.privacyPolicy input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 7px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  background-color: var(--green);
}

.privacyPolicy label {
  cursor: pointer;
  text-align: left;
}

.privacyPolicy a {
  color: var(--primary-color);
  text-decoration: underline;

}

.privacyPolicy a:hover {
  color: var(--green);
}