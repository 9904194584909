.accordionItem {
    cursor: pointer;
    color: var(--light-white);
}

.accordionTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}

.accordionTitle:hover{
    background-color: var(--green);
    color: var(--light-black);
}

.arrowIcon {
    width: 15px;
    height: 15px;
}

.accordionTitle:hover .arrowIcon {
    filter: invert(1);
}

.accordionContent {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--primary-dark);
    width: 100%;
    height: 100%;
    cursor: auto;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow-y: auto;
}

.close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 1.7rem;
    background-color: transparent;
    border: none;
    color: var(--light-white);
}

.close:hover {
    color: var(--green);
}

.validationInfos {
    margin-top: 20px;
    padding-left: 10px;
}

.infoContainer {
    padding: 10px;
    margin-top: 10px;
}

.validationTitle {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--light-white);
    text-align: center;
    border-bottom: 1px solid var(--light-white);
    padding-bottom: 5px;
    padding-top: 5px;
}