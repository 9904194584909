.container {
    height: auto; /* Altezza dinamica in base al contenuto */
    min-height: calc(100vh - 80px); /* Altezza minima della finestra meno l'altezza della navbar */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    padding: 20px; 
    box-sizing: border-box;
    overflow-y: auto; 
}

.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--primary-dark);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 8px; 
}

.validationPassed {
    color: var(--green);
}

.validationFailed {
    color: var(--red); 
}

.infoTitle {
    color: var(--primary-color);
}

.metadata {
    color: var(--light-white);
}

.results .validationImg {
    width: 80px; 
}

.results p {
    margin-top: 10px;
    font-size: 16px; 
    font-weight: bold;
}

.productInfo {
    margin-top: 20px; 
}

.productInfo h2 {
    font-size: 20px; 
    font-weight: bold;
    margin-bottom: 10px; 
}

.productInfo p {
    font-size: 16px; 
    margin-bottom: 5px; 
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin: 20px; 
}

.newButton {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-weight: bold;
    color: #76ABAE;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
    margin:5%;
  }
  
  .newButton:hover {
    background: #76ABAE;
    color: #272727;
    border-radius: 5px;
    cursor: pointer;
  }
  .detailsImageContainer{
    padding: 10px;
    display: flex;
    width: 400px;
    max-height: 350px;
  }
  .detailsCsrImage {
    width: 100%;
    height: auto;
    border-radius: 10px;

    object-fit: contain;
  }

  .failImg {
    width:300px;
  }

  .titleStep {
    font-size: 1.8rem;
    position: absolute;
    color: var(--light-white);
    margin-bottom: 30px;
    top: 120px;
    left: 0;
    right: 0;
    opacity: 0.9;
}

