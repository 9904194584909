.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  width: 100%;
  height: auto;
}

.logo {
  padding: 16px 0 16px 0;
  
  width: auto;
  height: auto;
}

.createAccount {
  color: #333;
  margin-top: 24px;
  font-size: 1.2rem;
}

.signinForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 40px 70px 30px 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* ombra leggera */
}

/* Stile per gli input all'interno del form */
.signinForm input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc; /* colore del bordo da abbinare agli altri moduli */
  border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color: var(--white) !important;
}
/* Stile per i pulsanti all'interno del form */
.signinForm button {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.869);
  border-radius: 10px;
  padding: 4px 12px 2px 12px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.signinForm button:hover {
  background-color: rgb(255, 255, 255);
}