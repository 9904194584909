.mainContainer {
  height:100%; 
  margin-bottom: 0;
  overflow-y: hidden; 
  background: var(--bg-color);
  background-image: url('../../assets/pngegg\ \(32\).png');
  background-repeat: no-repeat;
  background-size: cover;
}

   