.emailInputForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emailInput {
  display: flex;
  flex-direction: column;
  align-items: center;
}

 .emailInputLabel {
  display: block;
  margin: 8px auto 4px 0;
  font-weight: bold;
  font-family: "JetBrains Mono",monospace;
  font-size: 1.2rem;
}
.errorEmailInputLabel {
  font-size: 0.8em;
  color: rgb(187, 26, 26);
  margin-right: 15px;
  position: relative;
  top: -24px;
}

 .userBox {
  position: relative;
}

 .userBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;
  outline: none;
  background-color: transparent;
  border: none;
}

 .userBox .label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

 .userBox input:focus ~ .label,
 .userBox input:valid ~ .label {
  top: -20px;
  left: 0;
  color: #76ABAE;
  font-size: 12px;
}
