.form input {
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
  margin:5%;
}

.newButton:hover {
  background: #76ABAE;
  color: #272727;
  border-radius: 5px;
  cursor: pointer;
}





