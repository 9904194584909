.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
}
 
.resultsContainer {
  margin-top: 20px;
}

.title {
  color: var(--green);
}

.resultRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
 
.label {
  font-weight: bold;
  color: var(--light-white);
}
 
.value {
  margin-left: 10px;
  color: var(--light-white);
}
.newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
}

.newButton:hover {
  background: var(--green);
  color: var(--light-black);
  border-radius: 5px;
  cursor: pointer;
}

.newButton:hover .icon {
  transition: all 0.5s;
  fill: var(--black);
  filter: invert(0);
  --webkit-filter: invert(0);
}

.icon {
  width: 24px;  
  margin-right: 10px;  
  filter: invert(1);
  position: relative;
  top: 7px;
}

.detailsImageContainer{
  padding: 0px 0px 16px 0px;
  display: flex;
  width: 420px;
  max-height: 350px;
}
.detailsCsrImage {
  width: 100%;
  height: auto;
  border-radius: 10px;

  object-fit: contain;
}