:root {
  --primary-color: #7b9bc5;  
  --secondary-color: #f8dad4; 
  --accent: #ff5639;          
  --white: #FFFFFF;           
  --grey: #BDBDBD;           
  --black: #000;
  --green: #76ABAE; 
  --bg-color: #212121;
  --primary-dark: #222831;  
  --secondary-dark: #31363F;
  --light-white: #eee;
  --light-black: #272727;    
  --red: #dd2e44;   
}

.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: var(--bg-color);  
  background-repeat: no-repeat;

}
