.container {
  height: 100%;  
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
}

.floatingButtonContainer{
    z-index: 10001;
    padding: 20px;
    position: absolute;
    bottom: 160px;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 94px;
    width: 100%;
    height: auto;
    padding: 10px;
    z-index: 10000;

    
  }
  
  .flipCameraButton {
    width: auto;
    padding: 20px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 100%;
    background-color: #007bff;
    color: white;
    cursor: pointer;

    opacity: 0.5;
  }

  .shotCameraButton{
    padding: 20px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 100%;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    opacity: 0.5;
  }
  .myImagesButton{
    padding: 20px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  .customGalleryButton {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: inline-block;
    margin-right: 10px;
  }
  
  .stepper {
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 20px;
    background-color: transparent;
    z-index: 10002;
}

  .stepperButton {
    align-items: center;
    background: var(--light-white);
    border-radius: 10px;
    border-width: 0;
    box-sizing: border-box;
    color: var(--light-black);
    display: inline-flex;
    height: 28px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    font-weight: bold;
  }

  .stepperButton.active {
    background: var(--green);
}

.noSpin::-webkit-inner-spin-button,
.noSpin::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  .noSpin {
      -moz-appearance: textfield;
  }

.glass {
    background: var(--primary-dark);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 50px;
    transition: 0.6s;
    transform-style: preserve-3d;
}
  
  .stepperLine {
    width: 100%;
    height: 2px;
    background-color: var(--light-white);
    position: absolute;
    opacity: 0.5;
    bottom: 50%;
    left: 0;
    z-index: -1;
}

.webcamContainer {
  width: 100%;
  height: 70%;
  overflow: hidden;
  margin-bottom: 8%;
}

.webcam {
  width: 100%;
  height: 100%;

}
.captureImage{
  width: auto;
  height: 100%;
}
.activeCamera{
  background-color: var(--primary-dark);
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  padding: 12px 12px;
  border: none;
  border-radius: 100%;

  font-size: .8rem;
  font-weight: bold;

  z-index: 10001;
  position: relative;
  top: 80px;
  left: auto;

  cursor: pointer;
  transition: box-shadow 0.15s, transform 0.15s;

}
.activeCamera:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.activeCamera:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-3px);
}
.floatingButtonContainer {
  z-index: 10001;
  padding: 20px;
  position: absolute;
  bottom: 160px;
  
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 94px;
  width: 100%;
  height: auto;
  padding: 10px;
  z-index: 10000;
  
}

.flipCameraButton,
.shotCameraButton {
  background-color: #FCFCFD;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  padding: 20px 20px;
  border: none;
  border-radius: 100%;

  font-size: .8rem;
  font-weight: bold;
  
  z-index: 10001;
  position: relative;
  bottom: 32px;
  left: auto;
  margin-right: 4px;
  margin-left: 4px;
  cursor: pointer;
  transition: box-shadow 0.15s, transform 0.15s;
}
.flipCameraButton:focus, .shotCameraButton:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.flipCameraButton:hover, .shotCameraButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-3px);
}
.icon {
  width: 24px;   
}
.imageControls{
  position: relative;
  bottom:  16%;
}

.galleryContainer {
  position: relative;
  top: 5%;
  right: 0%;
}
.galleryButton {
  background-color: #FCFCFD;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  padding: 12px 12px;
  border: none;
  border-radius: 100%;
  font-size: .8rem;
  font-weight: bold;
  z-index: 10001;
  position: relative;
  top: 80px;
  left: auto;
  cursor: pointer;
  transition: box-shadow 0.15s, transform 0.15s;
}
.galleryButton:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.galleryButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-3px);
}

.discardButton, .confirmButton {
  background-color: #FCFCFD;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  padding: 12px 12px;
  border: none;
  border-radius: 20px;
  font-size: .8rem;
  font-weight: bold;
  z-index: 10001;
  cursor: pointer;
  transition: box-shadow 0.15s, transform 0.15s;
}

