.passwordInputForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.passwordInput{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordInputLabel {
  display: block;
  margin: 8px auto 4px 0;
  font-weight: bold;
  font-family: "JetBrains Mono",monospace;
  font-size: 1.2rem;
}


.password_eye {
  position: absolute;
  width: 32px;
  right: 0;
  opacity: 0.85;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.errorPasswordInputLabel {
  font-size: 0.8em;
  color: rgb(187, 26, 26);
  position: relative;
  top: 48px;
}

.userBox {
  position: relative;
  margin-bottom: 70px;
}

 .userBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  margin-bottom: -40px;
}

 .userBox .label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

 .userBox input:focus ~ .label,
 .userBox input:valid ~ .label {
  top: -20px;
  left: 0;
  color: #76ABAE;
  font-size: 12px;
}
