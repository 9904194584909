.webcamContainer {
    width: 100%;
    height: 100%;
  }
 
  .glass {
    background: var(--primary-dark);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.35);
    width: fit-content;
    height: 50%; 
    aspect-ratio: 16/9;

  }
 
  .webcam {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: contain;
  }
 
 
  .floatingButtonContainer {
    z-index: 10001;
    height: 0;
    margin-left: auto;
    margin-right: auto;
  }
   
  .shotCameraButton {
    background: var(--light-white);
    padding: 12px 12px;
    border: none;
    border-radius: 100%;
    font-size: .8rem;
    font-weight: bold;
    z-index: 10001;
    position: relative;
    bottom: -10px;
    left: auto;
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
    transition: box-shadow 0.15s, transform 0.15s;
    opacity: .5;
  }
 
 .shotCameraButton:hover {
    background-color: var(--green);
    opacity: 1;
    transition: all 0.5s;
  }
  .icon {
    width: 24px;  
  }
 
  .imageControls{
    position: relative;
    bottom:  16%;
  }

  .discardButton, .confirmButton {
    background: var(--light-white);
    padding: 12px 12px;
    margin: 5px;
    border: none;
    border-radius: 20px;
    font-size: .8rem;
    font-weight: bold;
    z-index: 10001;
    cursor: pointer;
    transition: box-shadow 0.15s, transform 0.15s;
  }
  .discardButton:hover {
    transition: all 0.5s;
    background-color: var(--green);
  }
 
  .confirmButton:hover {
    transition: all 0.5s;
    background-color: var(--green);
  }
 
.popoverRightArrow {
  width: 14px;
  margin-left:10px;
}
 
  .selectCamera {
    width: 100%;
    padding: 10px 20px;
    font-weight: bold;
    color: #76ABAE;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }
 
  .selectCamera:hover {
    background: #76ABAE;
    color: #272727;
    border-radius: 5px;
    cursor: pointer;
  }
.deviceList {
    background: #222831;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    padding: 20px;
 
    transition: 0.6s;
    transform-style: preserve-3d;
}
  .selectDeviceButton {
    width: 100%;
    font-weight: bold;
    color: #76ABAE;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
   
  }
  .noDevices {
    width: 100%;
    font-weight: bold;
    color: #76ABAE;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
   
  }
  .selectDeviceButton:hover {
    background: #76ABAE;
    color: #272727;
    border-radius: 5px;
    cursor: pointer;
  }

  .cropperContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .cropperContainer .reactEasyCrop {
    border-radius: 16px;
  }

  .titleStep {
      font-size: 1.8rem;
      position: absolute;
      color: var(--light-white);
      margin-bottom: 30px;
      top: 120px;
      left: 0;
      right: 0;
      opacity: 0.9;
  }