.container {
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  height: 90%;
}

.buttonContainer {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    min-width: 300px;
}

.image {
  width: 22px;
  height: 22px;
  filter: invert(1);
  margin-right: 8px;
}

.newButton {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 10px 20px;
    font-weight: bold;
    color: #76ABAE;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 20px;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
    width: 60%;
  }
  
  .newButton:hover {
    background: #76ABAE;
    color: #272727;
    border-radius: 5px;
    cursor: pointer;
  }
  .newButton:hover .image {
    filter: invert(0);
    transition: all .3s;
    --webkit-filter: invert(0);
  }

  .text {
    color: var(--light-white);
    font-size: 18px;
  }