/* MyProductsModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    position: relative;
    background: var(--primary-dark);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 20px;
    transition: 0.6s;
    transform-style: preserve-3d;
    width: 80%;
    max-width: 600px;
  }
  .h2 {
    color: var(--light-white);
    margin-bottom: 30px;
  }
  .productContainer{
    display: inline-flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 0;
    cursor: pointer;
  }
  .imageContainer {
    width: 80px;
  }
  .detailsContainer {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .productLabel, .csrCodeLabel {
    color: var(--light-black);
    display: flex;
    align-items: center;
  }
  .productName {
    color: var(--light-black);
    font-weight: bold;
    width: fit-content;
    padding-left: 10px;
    word-break: break-word;
    text-align: left;
  }
  .productCsrCode {
    color: var(--light-black);
    font-weight: bold;
    width: fit-content;
    padding-left: 10px;
  }
  .closeButton {
    color: var(--light-white);
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.7rem;
  }
  
  .closeButton:hover {
    color: var(--green);
  }
  
  .productList {
    display: grid;
    gap: 10px;
  }
  
  .imagePreview {
    width: 100%;
    height: auto;
    border-radius: 10px;
    display: flex;
  }
  
  .productDetails {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .textDetailsContainer{
    width: 90%;
    text-align: left;
    line-height: 16px;
  }
  .detailsImageContainer{
    padding: 10px;
    display: flex;
    width: 400px;
    max-height: 350px;
  }
  .detailsCsrImage {
    width: 100%;
    height: auto;
    border-radius: 10px;

    object-fit: contain;
  }

  .productTextContainer {
    display: flex;
    width: 70%;
  }

  .csrCodeContainer {
    display: flex;
    width: 30%;
  }

  .span {
    color: var(--light-white);
    word-break: break-word;
  }
  .pagingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px 0px 0px 0px;
  }

  .newButton {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-weight: bold;
    color: #76ABAE;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-bottom: 10px;
    margin-top: 10px;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
  }
  .newButton:hover {
    background: var(--green);
    color: var(--light-black);
    border-radius: 5px;
    cursor: pointer;
  }

  .icon {
    width: 24px;
  }
  .pageCount{
    font-size: 1.2rem;
    color: var(--light-white);
  }



  