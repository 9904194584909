.container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;  
    align-items: center;
    justify-content: center;
    padding-top: 1%;
}


::-webkit-scrollbar {
    display: none;
}

.title {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

p{
    color: #aaa;
    font-size: 14px;
  }

h2 {
  color: var(--text-light);
}

.image {
    width: 70%;  
    height: auto;  
    transition: transform 0.8s ease-in-out;
    border-radius: .8rem;
}

.buttonContainer {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.signText:hover{
  transform: none;
  box-shadow: none;
}

.signupForm {
    display: flex;
    padding: 40px 70px 30px 60px;
    color: var(--text-light);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* ombra leggera */
    margin-top: 24px;
  }
  
  /* Stile per gli input all'interno del form */
  .signupForm input {
    width: 80%;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc; /* colore del bordo da abbinare agli altri moduli */
    border-radius: 5px;
  }
  
  /* Stile per i pulsanti all'interno del form */
  .signupForm button {
    width: fit-content;
    border-radius: 10px;
    padding: 4px 12px 2px 12px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;

    border: none;
  }
  
  .signupForm button:hover {
    background-color: rgb(255, 255, 255);
  }
  .signInButton, .signUpButton {
    background-color: transparent;
    color: var(--text-light);
    font-size: 18px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 4px;
  }

  .h2 {
    margin:8px;
    font-family: "JetBrains Mono",monospace;
    font-size: 1.6rem;
  }

  .span{
   color: #aaa;
   font-size: 14px;
  }

  .newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
}

.newButton:hover {
  background: #76ABAE;
  color: #272727;
  border-radius: 5px;
  cursor: pointer;
}

.signContainer {
  width: 400px;
}

.a2 {
  color: #76ABAE;
  text-decoration: none;
  background-color: transparent;
  border: none;
}
.a2:hover {
  background: transparent;
  color: #eee;
  border-radius: 5px;
  cursor: pointer;
}

.glass {
  background: #222831;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 50px;
  transition: 0.6s;
	transform-style: preserve-3d;
  margin-bottom: auto;
  margin-top:auto;
}

@media (max-width: 768px) {
    .container {
      margin-top: 7%;
    }
    .signContainer {
      width: 280px;
      height: 100%;
    }
  }