.glass {
  background: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 50px;
  transition: 0.6s;
  transform-style: preserve-3d;
  min-width: 400px;
}
.inputContainer {
    position: relative;
    flex: 1;
    margin: 0 5px;
    display: flex;
    align-items: center;
}
  
.idInput {
    flex: 1;
    padding: 10px 40px 10px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.nextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  padding-left: 20px;
}


.rightArrow {
    position: relative;
    top: 20%;
    padding: 25px 25px;
    border: none;
    border-radius: 100%;
    background-color: transparent;
    background-image: url('../../../assets/icons/arrow_right.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: box-shadow 0.15s, transform 0.15s;
}

.rightArrow:hover {
    transition: all 0.5s;
    background-color: var(--green);
}

.next {
  color: var(--green);
  text-align: center;
  font-size: 1.2rem;
  padding-right: 3px;
}

  .noSpin::-webkit-inner-spin-button,
  .noSpin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .noSpin {
    -moz-appearance: textfield;
  }

  .CsrIdTitle {
    color: var(--light-white);
    margin-bottom: 30px;
    opacity: 0.8;
  }

  .userBox {
    position: relative;
  }
  
   .userBox input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    background: transparent;
  }
  
   .userBox .label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
  }
  
   .userBox input:focus ~ .label,
   .userBox .hLabel {
    top: -20px;
    left: 0;
    color: #76ABAE;
    font-size: 12px;
  }


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
  
.newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
  margin:5%;
}

.newButton:hover {
  background: #76ABAE;
  color: #272727;
  border-radius: 5px;
  cursor: pointer;
}

.newButton.disabledButton {
  opacity: 0.3;
  pointer-events: none;
}

.errorGenericInputLabel {
  font-size: 0.8em;
  color: rgb(187, 26, 26);
  margin-right: 15px;
  position: relative;
  bottom:  20px;
}

.titleStep {
  font-size: 1.8rem;
  position: absolute;
  color: var(--light-white);
  margin-bottom: 30px;
  top: 120px;
  left: 0;
  right: 0;
  opacity: 0.9;
}