.container {
    width: 100%; 
    height: 90px; 
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 0;
    z-index: 1005; 
    position: fixed;
    top: 0;
    transition: bottom 1.5s ease;
    background: #222831;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
}

.container a {
    color: #000000;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: color 0.4s ease;
}

.navLink {
    color: #000000;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    transition: color 0.3s ease;
    font-family: "JetBrains Mono",monospace;
    font-size: 18px;
    margin-right: 10%;
}

.logoContainer {
    width: 120px;

    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding-right: 32px;
    margin-top: 10px;

    border: none;
    background-color: transparent;
    cursor: pointer;
}

.logoContainer img {
    max-width: 100%;
    height: auto;
}


.navLink .icon {
    width: 40px; 
}

.navText {
    margin: 5px;
}

.slideIn {
    bottom: 0;
}

.iconHome {
    height: 30px;
    width: 30px;
    filter: invert(.9);
    --webkit-filter: invert(.9);
}

.container a:hover p {
    background-color: #76ABAE;
    color: #222831;
}


.container p {
    font-size: 20px;
    color: #76ABAE;
    padding: 2px;
    margin: 0;
    border-radius: 5px;
    margin-top: 8px;
}

.textSelected {
    background-color: #76ABAE;
    color: #222831;
}


@media (max-width: 768px) {
    .container {
        height: 60px;
        padding-top: 5px;
    }

    .navLink {
        margin-right: 5%;
    }

    .logoContainer {
        width: 80px;
        height: 55px;
    }

    .iconHome {
        height: 20px;
        width: 20px;
    }

    .container p {
        font-size: 12px;
    }
}


