.searchInput {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;
}


.userBox {
  position: relative;
}

.userBox input {
    width: calc(100% - 32px); 
    padding: 10px 0px 10px 32px;
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    background: transparent;
}


.icon {
    width: 24px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

