.container {  
  height: 100%;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
}

.faqTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--light-white);
    margin-bottom: 20px;
}

.faqText {
    font-size: 18px;
    color: var(--light-white);
    margin-bottom: 10px;
}

.glass {
  background: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 50px;
  transition: 0.6s;
	transform-style: preserve-3d;
}