.glass {
  width: fit-content;
  background: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 50px;
  transition: 0.6s;
  transform-style: preserve-3d;
  margin-bottom: auto;
  margin-top:auto;
  }

.title {
  color: var(--light-white);
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 400px;
  box-sizing: border-box;
}

 .userBox {
  position: relative;
  margin-top: 20px;
}

 .userBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
}

 .userBox .label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.userBox input:focus ~ .label,
.userBox .hLabel
  {
  top: -20px;
  left: 0;
  color: #76ABAE;
  font-size: 12px;
}

.newButton {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #76ABAE;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 3px;
  background-color: transparent;
  border: none;
  }
  
  .newButton:hover {
  background: #76ABAE;
  color: #272727;
  border-radius: 5px;
  cursor: pointer;
  }

  .newButton.disabledButton {
    opacity: 0.3;
    pointer-events: none;
  }

  .updateImageInput {
    padding: 10px 20px;
    font-weight: bold;
    color: transparent; /* Testo trasparente */
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 24px;
    letter-spacing: 3px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .updateImageInput:hover {
    color: #272727; /* Cambio colore solo in hover */
  }
  .updateImageInput::-webkit-file-upload-button {
    visibility: hidden;
  }
  .updateImageInput::before {
    content: 'Select Product Image';
    display: inline-block;
    transition: .5s;
    color: #76ABAE;
    padding: 8px 18%;
    border-radius: 5px;
    cursor: pointer;
  }
  .updateImageInput:hover::before {
    background: #76ABAE;
    color: #272727;
    border-radius: 5px;
    cursor: pointer;
  }
  .imagePreviewContainer {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    flex-direction: row;
    margin-top: 20px; 
  }
  .imagesPreview {
      width: 120px;
      height: 100%;
      object-fit: cover;

  }
  .imagePreviewButton {
    margin: 0;
    padding: 0;
    width: 120px;
    height: 120px;
    border: none;
    position: relative;
    overflow: hidden;
    background: none;

    margin-left: 8px;
    margin-right: 8px;
    border-radius: 10px;

  }
  .imagePreviewButton:hover {
    transition: .3s;
    opacity: 0.5;
  }
  .deleteButton {
    position: absolute; 
    top: 20px; 
    left: 15%; 
    width: 80px; 
    height: 80px; 
    border: none; 
    padding: 0; 
    margin: 0; 
  }
  .trashImageIcon {
    width: 80px;
  }
  input[type='file'] {
    color: transparent;
    
  }

  /*Fai classi comuni per Bottone Submit/FileUpload) */